import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import { Amplify } from 'aws-amplify'
import './assets/sass/style.scss'
import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'

const app = createApp(App)
const pinia = createPinia()

app.use(pinia)
app.use(router)
app.use(CoreuiVue)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.mount('#app')

Amplify.configure({
	Auth: {
		// REQUIRED - Amazon Cognito Region
		region: 'eu-west-2',

		// OPTIONAL - Amazon Cognito User Pool ID
		userPoolId: 'eu-west-2_jaAeZgrAj',

		// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
		userPoolWebClientId: '56p8c7en93ue7sdbh3q2jtp532',

		// OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
		mandatorySignIn: false,

		// OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
		// 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
		signUpVerificationMethod: 'link',

		// OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
		authenticationFlowType: 'USER_PASSWORD_AUTH',

		// OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
		// clientMetadata: { myCustomKey: 'myCustomValue' },
	}
})