<template>
	<router-view />
	<CToaster placement="top-center" visible>
		<CToast v-for="(toast, index) in mainStore.toasts" :key="index" :autohide="true" :delay="6000" :color="toast.color" class="text-white align-items-center">
			<div class="d-flex">
				<CToastBody>{{ toast.message }}</CToastBody>
				<CToastClose class="me-2 m-auto" white />
			</div>
		</CToast>
	</CToaster>
</template>

<script>
import { useMainStore } from '@/stores/mainStore'

export default {
	name: 'App',
	setup() {
		const mainStore = useMainStore()
		return {
			mainStore
		}
	},
}
</script>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
</style>
