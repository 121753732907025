// import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import { useMainStore } from '@/stores/mainStore'
import { Auth } from 'aws-amplify'

const routes = [
	{
		path: '/signup',
		name: 'SignUp',
		component: () => import('@/views/pages/SignUp.vue'),
	},
	{
		path: '/login',
		name: 'LogIn',
		component: () => import('@/views/pages/Login.vue'),
	},
	{
		path: '/',
		name: 'Home',
		component: () => import('@/layouts/DefaultLayout'),
		redirect: '/tag-management',
		children: [
			// If not using sample dashboard:
			// {
			// 	path: '/my-dashboard',
			// 	name: 'MyDashboard',
			// 	component: () => import('@/views/pages/MyDashboard.vue'),
			// },
			{
				path: '/my-dashboard',
				name: 'MyDashboard',
				component: () => import('@/views/pages/Dashboard.vue'),
			},
			{
				path: '/user-management',
				name: 'UserManagement',
				component: () => import('@/views/pages/UserManagement.vue'),
			},
			{
				path: '/tag-management',
				name: 'TagManagement',
				component: () => import('@/views/pages/TagManagement.vue'),
			},
			{
				path: '/reports/:type',
				name: 'Reports',
				component: () => import('@/views/pages/Reports.vue'),
			},
			{
				path: '/profile',
				name: 'MyProfile',
				component: () => import('@/views/pages/Profile.vue'),
			},
			{
				path: '/settings',
				name: 'Settings',
				component: () => import('@/views/pages/Settings.vue'),
			},
			{
				path: '/alerts',
				name: 'Alerts',
				component: () => import('@/views/pages/Alerts.vue'),
			},
			{
				path: '/search-results',
				name: 'SearchResults',
				component: () => import('@/views/pages/SearchResults.vue'),
			},
		],
	},
]

const router = createRouter({
	history: createWebHashHistory(process.env.BASE_URL),
	routes,
	scrollBehavior() {
		// always scroll to top
		return { top: 0 }
	},
})

router.beforeResolve((to, from, next) => {

	Auth.currentSession()
		.then((data) => {
			console.log(data)
			if (from.name == 'LogIn') {
				useMainStore().createToast({ message: 'Logged in 👍' })
			}
			next()
		})
		.catch((err) => {
			console.log('Not authorised -', err)

			if (to.name !== 'LogIn' && to.name !== 'SignUp') {
				useMainStore().createToast({ message: 'Not logged in', color: 'warning' })
				next({ name: 'LogIn' })
			} else {
				next()
			}
		})

})

export default router
