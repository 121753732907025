import { defineStore } from 'pinia'
import { Auth } from 'aws-amplify'
import router from '@/router'

export const useMainStore = defineStore('mainStore', {
	state: () => ({
		// apiBaseURL: 'https://api.imlost.uk/',
		apiBaseURL: 'https://au85ioo406.execute-api.eu-west-2.amazonaws.com/rc/',
		requestOptions: {},
		sidebarVisible: true,
		sidebarUnfoldable: false,
		loading: false,
		toasts: []
	}),
	actions: {
		toggleSidebar() {
			this.sidebarVisible = !this.sidebarVisible
		},

		toggleUnfoldable() {
			this.sidebarUnfoldable = !this.sidebarUnfoldable
		},

		updateSidebarVisible(payload) {
			this.sidebarVisible = payload
		},

		setRequestOptions(method) {
			this.requestOptions = {
				method: method,
				headers: {
					'Authorization': this.getIdToken,
				}
			}
		},

		async refreshUserSession() {
			try {
				const currentSession = await Auth.currentSession()
				console.log(currentSession)
				// Do something with the new session
			} catch (error) {
				console.log(error)
				this.createToast({ message: error, color: 'danger' })
				router.push('/login')
			}
		},

		async updateCognitoDetails(data) {
			// console.log('updating Cognito', data.userName, data.email, data.phone)
			this.loading = true
			const user = await Auth.currentAuthenticatedUser()
			await Auth.updateUserAttributes(user, { email: data.email, phone_number: data.phone })
				.then((msg) => {
					// console.log(msg)
					this.loading = false
					if (msg === 'SUCCESS') {
						this.createToast({ message: 'Profile details updated 👍' })
					} else {
						this.createToast({ message: 'Server error', color: 'danger' })
					}
				})
				.catch((err) => {
					this.loading = false
					this.createToast({ message: err.message })
				})
		},

		changeCognitoPassword(data) {
			console.log('update Cognito password', data)
		},

		updateProfileDetails(data) {
			this.setRequestOptions('PATCH')
			let updateTelURL = this.apiBaseURL + 'profile?tel=' + data.phone
			console.log(updateTelURL)
			console.log('updating user database', data)
		},

		createToast(data) {
			this.toasts.push({
				color: data.color ? data.color : 'info',
				message: data.message
			})
		}

	},
	getters: {
		getIdToken() {
			let storageKey = localStorage.getItem('storageKey')
			return localStorage.getItem(storageKey + '.idToken')
		},
		getAllUserData() {
			let storageKey = localStorage.getItem('storageKey')
			let userData = JSON.parse(localStorage.getItem(storageKey + '.userData'))
			return userData
		},
		getUserEmail() {
			return this.getAllUserData?.UserAttributes.find(item => item.Name == 'email').Value
		},
		getUserPhone() {
			return this.getAllUserData?.UserAttributes.find(item => item.Name == 'phone_number').Value
		}
	},
})